export const siteData = {
  "name": "SINANO",
  "address": "51 RUE DU GENERAL DE GAULLE",
  "postCode": "67520",
  "city": "KIRCHHEIM",
  "phone": "0310286961",
  "url": "https://sinano.pro",
  "urlSub": "sinano.pro",
  "price": "price_1QIER6AgViOBTEyEQqyXvjXm",
  "pk": "pk_live_51QIDfoAgViOBTEyEjOVhqm0jMhXaNQ8Icn8fxP9unXJDZHE1oHABffErMth2xPQDSnQRGxyaEmReKQXIqSDdf6Yr00puDfXKf8",
  "sk": "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"
};